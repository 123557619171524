import React from 'react'
import { loggedIn, getToken, isAdmin } from '../services/auth.service'
import { Redirect, Route } from 'react-router-dom'

const AdminRoute = ({ component: Component, ...rest }) => {
    // Add your own authentication on the below line.
    const isLoggedIn = loggedIn()
    const token = getToken()
    const userIsAdmin = isAdmin()
    return (
        <Route
            {...rest}
            render={props =>
                isLoggedIn ? (
                    userIsAdmin ?
                        (
                            <Component token={token} {...props} />
                        ) : (
                            <Redirect to={{ pathname: '/error-401', state: { from: props.location } }} />
                        )
                ) : (
                        <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                    )
            }
        />
    )
}

export default AdminRoute