// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
import React from 'react'
import { loggedIn, getToken, userHasPermission, isAggregateUser } from '../services/auth.service'
import { Redirect, Route } from 'react-router-dom'

const PrivateRoute = ({ component: Component, ...rest }) => {
    // Add your own authentication on the below line.
    const isLoggedIn = loggedIn()
    const token = getToken()
    const hasPermission = rest.path.startsWith('/tools/newsletter/configure') ? userHasPermission('/tools/newsletter/configure') : userHasPermission(rest.path)
    const aggregateUser = isAggregateUser()
    return (
        <Route
            {...rest}
            render={props =>
                isLoggedIn ? (
                    hasPermission ?
                        (
                            <Component token={token} isAggregateUser={aggregateUser} {...props} />
                        ) : (
                            <Redirect to={{ pathname: '/error-401', state: { from: props.location } }} />
                        )
                ) : (
                        <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                    )
            }
        />
    )
}

export default PrivateRoute