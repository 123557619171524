import cookie from 'js-cookie';

export const login = (token, aggregate, fhiName, username, role, hierarchyLevel, permissions, state, region, stateId, regionId) => {
  cookie.set('token', token, { expires: 1 });
  cookie.set('aggregate', aggregate, { expires: 1 });
  cookie.set('fhiName', fhiName, { expires: 1 });
  cookie.set('username', username, { expires: 1 });
  cookie.set('role', role, { expires: 1 });
  cookie.set('hierarchyLevel', hierarchyLevel, { expires: 1 });
  cookie.set('state', state, { expires: 1 });
  cookie.set('region', region, { expires: 1 });
  cookie.set('stateId', stateId, { expires: 1 });
  cookie.set('regionId', regionId, { expires: 1 });
  window.localStorage.setItem('permissions', permissions);
}

export const logout = () => {
  cookie.remove('token');
  cookie.remove('aggregate');
  cookie.remove('fhiName');
  cookie.remove('username');
  cookie.remove('role');
  cookie.remove('hierarchyLevel');
  window.localStorage.removeItem('permissions');
  // to support logging out from all windows
  window.localStorage.setItem('logout', Date.now());
}

export const loggedIn = () => {
  const token = cookie.get('token');
  if (!token) {
    return false;
  }
  return true;
}

export const getToken = () => {
  return cookie.get('token');
}

export const getUserRole = () => {
  return cookie.get('role');
}

export const userHasPermission = (path) => {
  const userPermissions = window.localStorage.getItem('permissions');
  if (userPermissions) {
    const permissions = JSON.parse(userPermissions);
    if (permissions.filter(perm => perm.Path === path).length > 0)
      return true;
    else
      return false;
  }
  return false;
}

export const getUserPermissionsByCategory = (category) => {
  const userPermissions = window.localStorage.getItem('permissions');
  if (userPermissions) {
    const permissions = JSON.parse(userPermissions);
    return permissions.filter(perm => perm.Category.toLowerCase() === category.toLowerCase())
  }
  return [];
}

export const isAdmin = () => {
  const role = cookie.get('role');
  if (role && role.toLowerCase() === 'admin')
    return true;
  return false;
}

export const isAggregateUser = () => {
  const aggregateUser = cookie.get('aggregate');
  if (aggregateUser && aggregateUser.toLowerCase() === 'true')
    return true;
  return false;
}